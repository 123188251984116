<template>
    <v-container class="d-flex flex-wrap" fluid>
        <v-card
            class="ma-1 pa-0"
            width="350px"
            v-for="candidate in trust_votes"
            :key="candidate.id"
            :color="selected_pupil && candidate.trust_votes.some(tv => tv.id == selected_pupil.id) ? 'green lighten-4' : ''"
            >
            <v-card-title primary-title class="text-capitalize d-flex py-0">
                {{candidate.username}} <v-spacer></v-spacer>{{candidate.trust_votes.length}}
            </v-card-title>
            <v-card-text>
                <v-chip :color="selected_pupil == vote ? 'green darken-2' : ''" :dark="selected_pupil == vote"  :class="vote.election.fixed_trust_persons.includes(candidate.id) ? 'font-weight-bold' : ''" @click="selected_pupil = vote" class="text-capitalize mr-1" v-for="vote in candidate.trust_votes" :key="vote.id + 'vote'">{{vote.username}}</v-chip>
            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>
export default {
computed: {
    trust_votes () {
        let candidates = this.$store.state.users.all.filter(user => user.election && user.election.electable)
        let pupils =  this.$store.state.users.all.filter(user => user.election && user.election.voted)
        let ppls = []
        for (let i = 0; i<pupils.length;i++) {
            let pupil = pupils[i]
            let voted_trust_persons = []
            pupil.election.fixed_trust_persons = []
            for (let a = 0; a < pupil.election.trust_persons.length; a++) {
                voted_trust_persons.push(candidates.filter(c => c.id == pupil.election.trust_persons[a])[0])
            }
            if (voted_trust_persons.filter(v => v.election && v.election.first_trust_person).length == 1 || voted_trust_persons.length == 2 ) {
                pupil.election.fixed_trust_persons.push(voted_trust_persons.filter(v => v.election && v.election.first_trust_person)[0].id)
            }
            if (voted_trust_persons.length == 2) {
                console.log(voted_trust_persons.filter(v => !pupil.election.fixed_trust_persons.includes(v.id))[0].id)
                pupil.election.fixed_trust_persons.push(voted_trust_persons.filter(v => !pupil.election.fixed_trust_persons.includes(v.id))[0].id)
            }
            ppls.push(pupil)
        }
        for(let i = 0; candidates.length > i; i++) {
            candidates[i].trust_votes = ppls.filter(user => user.election && user.election.trust_persons.includes(candidates[i].id))
        }
        return candidates
    },
},
data () {
    return {
        selected_pupil: null
    }
}
}
</script>