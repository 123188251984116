import axios from 'axios'
import urls from '../services/urls.js'

class CurrentUser {
  async login (context, user) {
    let message = {
      type: null,
      content: null
    }
    this.error = false
    const response = await axios.post( urls.login_url() + 'users/sign_in', {
      user: {
        username: user.name,
        password: user.password
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .catch(error => {
        message.type="error"
        message.content="Login fehlgeschlagen."
        console.dir(error)
      })
    if (response) {
      console.dir(response)
      context.commit('setUser', response.data)
      message.type="success"
      message.content="Hallo " + response.data.username + ", du hast dich erfolgreich eingeloggt!"
    } else {
      message.type="error"
      message.content="Login fehlgeschlagen."     
    }
    context.commit('base/notificate', message, {root:true})
  }
}

export default new CurrentUser()