import GlobalMixin from './mixins/global'
import TitleMixin from './mixins/title'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import ActionCableVue from 'actioncable-vue';
import urls from './services/urls.js'
import colors from 'vuetify/lib/util/colors'

class base {
    add_basejs() {
        Vue.mixin(GlobalMixin)
        Vue.mixin(TitleMixin)
        Vue.use(ActionCableVue, {
            debug: true,
            debugLevel: 'error',
            connectionUrl: urls.cable_url(),
            connectImmediately: true,
        })
    }
    theme () {
        return {
            themes: {
              light: {
                primary: colors.blue.lighten2,
                secondary: colors.grey.lighten2,
                edit: colors.red.lighten2,
                editing: colors.yellow.lighten4,
                accent: colors.shades.black,
                error: colors.red.accent3,
                neutral: colors.white
              },
              dark: {
                primary: colors.purple.darken4,
                edit: colors.red.darken3,
                secondary: colors.grey.darken3,
                editing: colors.brown.darken4,
                neutral: colors.grey.darken4
              },
            }
        }
    }
}



export default new base()

