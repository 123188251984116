<template>
  <div :class="formEnabled ? 'component d-flex' : 'ml-4'" style="min-width:250px" class="px-2 mt-2">
    <v-btn
      dense
      v-if="!formEnabled"
      color="primary"
      elevation="2"
      fab
      rounded
      small
      @click="enableForm"
    >
      <v-icon medium>add</v-icon>
    </v-btn>
    <v-file-input
      dense
      v-if="formEnabled && !uploadStarted"
      label="Bild hochladen"
      @change="selectFile"
      v-model="fileInput"
      accept="image/*"
    ></v-file-input>
    <v-btn
      class="ml-2"
      dense
      v-if="formEnabled && !uploadStarted"
      :disabled="fileInput.length < 1"
      color="green"
      fab
      rounded
      small
      @click="uploadFiles"
    >
      <v-icon medium>forward</v-icon>
    </v-btn>
  </div>
</template>

<script>
import UploadService from '../project_base/services/UploadFilesService'
export default {
  props: {user: Object},
  data () {
    return {
      formEnabled: true,
      fileInput: [],
      uploadCounter: 0,
      uploadStarted: false,
      currentFiles: undefined,
      errors: []
    }
  },
  methods: {
    enableForm () {
      this.formEnabled = !this.formEnabled
      this.$emit('uploadStarted', true)
    },
    selectFile (files) {
      this.currentFiles = files
      this.uploadCounter = 0
    },
    uploadFiles () {
      this.uploadStarted = true
      if (!this.currentFiles) {
        this.message = 'Please select a file!'
        return
      }
      this.upload(this.currentFiles)

      this.fileInput = []
    },
    upload (file) {
      this.message = ''
      this.fileInput = []
      this.uploadStarted = false
      UploadService.upload(file, this.user.id,this.$store.state.base.current_user.authentication_token)
        .then((response) => {
          this.message = response.data.message
          if (response.data.success === 'true') {
            this.uploadCounter++
          } else {
            this.errors.push(response.data.errors)
          }
        })
    },
    closeUpload (filesUploaded) {
      this.formEnabled = false
      this.fileInput = []
      this.uploadCounter = 0
      this.uploadStarted = false
      this.currentFiles = undefined
      if (filesUploaded) {
        this.getContents()
      }
      this.$emit('uploadStopped', false)
    }
  },
  computed: {
    progress () {
      if (this.currentFiles) {
        return Math.round((100 * this.uploadCounter) / this.currentFiles.length)
      } else {
        return null
      }
    }
  },
  watch: {
    progress: function () {
      if (this.progress === 100) {
        setTimeout(() => {
          this.closeUpload(true)
          this.refreshCurrentFolder()
        }, 500)
      }
    },
    currentFolder () {
      this.closeUpload()
    }
  }
}
</script>

<style scoped>
  .component {
    width: 100%;
  }
</style>
