const application = {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    getAll (context) {
      console.log(context)
    }
  }
}

export default application