<template>
  <v-avatar :size="size" :color="background ? color : ''">
    <v-overlay
      v-if="overlay"
      absolute
      color="black"
      opacity="0.5"
    > 
    </v-overlay>
    <img
      :src="user.avatar + '&size=' + size + 'x' + size"
      :alt="user.username"
    >
  </v-avatar>
</template>
<script>
export default {
  props: {
    user: Object,
    size: Number,
    background: Boolean,
    overlay: Boolean
  },
  computed: {
    color () {
      if (this.user.is_admin) return "orange"
      else if (this.user.is_employee) return "blue"
      else return "green"
    }
  }
}
</script>
