<template>
<v-navigation-drawer
        permanent
        expand-on-hover
        clipped
      >
        <v-list>
          <v-list-item class="px-2">
            <Avatar
              :user="$store.state.base.current_user"
              :size="40"
              background
            />
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{$store.state.base.current_user.username}}
              </v-list-item-title>
              <v-list-item-subtitle>mail@test.de</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <slot></slot>
      </v-navigation-drawer>  
</template>

<script>
import Avatar from './Avatar.vue'
export default {
  components: {
    Avatar,
  },
  methods: {
    link (url) {
      if (this.$route.path != url) {
        this.$router.push(url)
      }
    }
  }
}
</script>