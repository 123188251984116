import adminhttp from './http-auth'

class UploadFilesService {
  upload (file, userId, token) {
    var reader = new FileReader()
    reader.readAsBinaryString(file)
    const formData = new FormData()

    formData.append('file', file)
    formData.append('userId', userId)
    return adminhttp.auth(token).post('/user_manager/uploadImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default new UploadFilesService()
