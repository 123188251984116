<template>
    <v-container class="d-flex flex-wrap" v-if="$store.state.users.all.length > 0">
        <v-card class="mr-3 mb-3" v-for="user in $store.state.users.all.filter(user => user.election && user.election.electable).sort(function (a, b) {
            return a.username.localeCompare(b.username)
        })" :key="user.id">
            <v-card-title primary-title class="">
                {{user.username}}
            </v-card-title>
            <v-img v-if="user.picture" max-height="230" max-width="230" class="ml-2" :src="base_url + user.picture"></v-img>
            <v-img v-else max-height="230" max-width="230" :src="user.avatar"></v-img>
            <UploadImage :user="user"></UploadImage>
        </v-card>
    </v-container>
</template>

<script>
import UploadImage from "../components/uploadImage.vue";
import urls from "../project_base/services/urls";

export default {
    computed: {
        user() {
            if (this.$route.params.id) {
                return this.$store.state.users.all.filter(user => user.id == this.$route.params.id)[0];
            }
            else
                return this.$store.state.base.current_user;
        }
    },
    data() {
        return {
            base_url: urls.base_url()
        }
    },
    components: { UploadImage }
}
</script>