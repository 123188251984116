import LoginService from '../../services/login.js'

const base = {
  namespaced: true,
  state: {
    current_user: null,
    notification: null,
    dark_mode: false
  },

  mutations: {
    setUser(state, user) {
      state.current_user = user
      if (user != null) {
        state.dark_mode = user.dark_mode
      }
    },
    toggleDarkmode(state, value) {
      if (state.current_user != null) {
        state.current_user.dark_mode = value
      }
      state.dark_mode = value
    },
    notificate(state, message) {
      state.notification = message
    },
  },

  actions: {
    async login(context, user) {
      await LoginService.login(context, user)
      if (context.state.current_user) {
        await context.dispatch('application/getAll', null, { root: true })
      }
    },
    logout(context) {
      window.sessionStorage.clear()
      localStorage.clear()
      context.commit('setUser', null)
    },
  }
}


export default base