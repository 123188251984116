import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import base from './project_base/base.js'


Vue.config.productionTip = false

base.add_basejs()
Vue.use(Vuetify)
let vuetify = new Vuetify({
  theme: base.theme()
})

new Vue({
  router,
  store,
  vuetify: vuetify,
  render: h => h(App)
}).$mount('#app')
