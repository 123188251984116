<template>
  <v-app>
    <v-main v-resize="onResize">
      <AppBar>
        <template v-slot:left></template>
        <template v-slot:right></template>
      </AppBar>
      <div class="d-flex fill-height">
        <NavigationDrawer v-if="$store.state.base.current_user">
          <v-list nav dense>
            <v-list-item link @click="link_to('/candidates')" v-if="candidates_permission">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Kandidat*innen</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="link_to('/wahlen')">
              <v-list-item-icon>
                <v-icon>mdi-vote</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Wahlen</v-list-item-title>
            </v-list-item>
          </v-list>
        </NavigationDrawer>
        <div>
          <router-view v-if="$store.state.base.current_user" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./project_base/components/AppBar.vue";
import NavigationDrawer from "./project_base/components/NavigationDrawer.vue";
import permission from "./project_base/services/permission"

export default {
  title: 'Wahlen',
  name: "App",
  components: {
    AppBar,
    NavigationDrawer,
  },
  computed: {
    candidates_permission () {
      return permission.check(this.$store.state.base.current_user, "election_add_candidates")

    },
    election_permission () {
      return permission.check(this.$store.state.base.current_user, "see_election_list")
    }
  },
  data: () => ({
    windowSize: {
      y: 0,
      x: 0
    }
  }),
  mounted() {
    this.$vuetify.theme.dark = this.$store.state.base.dark_mode;
    this.$cable.subscribe({
      channel: "ElectionUserChannel",
    });
    this.$store.dispatch("users/getUsers")
    this.onResize()
  },
  watch: {
    "$store.state.base.dark_mode": function (dark_mode) {
      this.$vuetify.theme.dark = dark_mode;
    },
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
  channels: {
    ElectionUserChannel: {
      connected() {
        console.log("ElectionUserChannel connected");
        
      },
      rejected() {
        console.log("connection rejected");
      },
      received(data) {
        this.$store.commit('users/update_user', data)
        
      },
      disconnected() {
        this.login_channel_subscribed = false;
        console.log("connection lost!!");
      },
    },
  },
};
</script>
