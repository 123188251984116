<template>
  <div>
    <v-btn v-if="!$store.state.base.current_user" @click="dialog = !dialog">login</v-btn>
    <v-btn v-else @click="logout()">logout</v-btn>
    <v-dialog v-if="!$store.state.base.current_user" v-model="dialog" width="400" persistent>
      <Notifications style="width:100%; position:relative" />
      <v-card>

        <v-card-title>
          Vertrauenspersonenwahl
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field :error="error" v-model="user.name" label="Benutzername" required></v-text-field>
            <v-text-field :error="error" v-model="user.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'" name="input-10-1" counter label="Passwort" hint="Dein Ubuntu Passwort"
              @keyup.enter.native="login" @click:append="toggle_password"></v-text-field>
            <v-card-actions>
              <v-btn @click="dialog = false" color="red" text>
                abbrechen
              </v-btn>
              <v-spacer />
              <v-btn @click="login" :loading="loading" color="green" text>
                login
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Notifications from './notifications.vue'

export default {
  components: {
    Notifications
  },
  props: {
    notifications: Array
  },
  data() {
    return {
      dialog: true,
      user: {
        name: '',
        password: ''
      },
      show1: false,
      error: null,
      loading: false
    }
  },
  methods: {
    async login() {
      this.loading = true
      await this.$store.dispatch('base/login', this.user)
      if (this.$store.state.base.current_user) {
        this.user.username = ''
        this.dialog = true
        this.$vuetify.theme.dark = this.$store.state.base.current_user?.dark_mode
      }
      this.user.password = ''
      this.loading = false
    },
    toggle_password() {
      this.show1 = !this.show1
    },
    logout() {
      this.$store.dispatch('base/logout')
      this.$store.commit('application/notificate', { type: "error", content: "Du hast dich ausgeloggt!" })
      this.$router.go()
    },
  }
}
</script>
