import Vue from 'vue'
import Vuex from 'vuex'
import BaseModule from '../project_base/store/modules/base_module.js'
import ApplicationModule from './modules/application_module.js'
import createPersistedState from 'vuex-persistedstate'
import UsersModule from './modules/users_module.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base: BaseModule,
    application: ApplicationModule,
    users: UsersModule,

  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ]
})
