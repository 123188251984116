<template>
  <v-app-bar color="primary" app dense fixed clipped-left>
    <slot name="left"></slot>
    <v-spacer />
    <slot name="right"></slot>
    <v-icon
      color="accent"
      @click="$store.commit('base/toggleDarkmode', !$store.state.base.dark_mode)"
    >
      {{ $vuetify.theme.dark ? "mdi-weather-night" : "mdi-weather-sunny" }}
    </v-icon>
    <div v-if="$store.state.base.current_user">
      <v-switch
        v-if="$store.state.base.current_user"
        prepend-icon="edit"
        v-model="edit_mode"
        color="blue"
        dark
        hide-details
        @change="toggleEdit"
      ></v-switch>
    </div>
    <LoginScreen />
  </v-app-bar>
</template>

<script>
import LoginScreen from "./LoginScreen.vue";
export default {
  components: {
    LoginScreen,
  },
  data() {
    return {
      edit_mode: false,
    };
  },
  methods: {
    toggleEdit(val) {
      this.$store.commit("application/toggleEditMode", val);
    },
  },
};
</script>