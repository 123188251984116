<template>
    <div>
      <v-alert
        class="mb-1 d-flex justify-center"
        v-for="(alert, index) in notifications"
        :key="index"
        style="z-index:150"
        dark
        :color="alertColor(alert.type)"
        elevation="20"
      >
      {{alert.content}}
      </v-alert>
    </div>
  
</template>

<script>
export default {
  data () {
    return {
      notifications: [],
    }
  },
  methods: {
    alertColor (type) {
      switch(type) {
        case "success":
          return "green lighten-1"
        case "error":
          return "red lighten-2"
        default:
          return "blue lighten-2"
      }
    },
  },
  watch: {
    '$store.state.application.notification': function (n) {
      if (n) {
        this.notifications.push(n)
        const self = this
        setTimeout(function(){ 
          self.notifications.shift()
        }, 4000);
        this.$store.commit('application/notificate', null)
      }
    },
  }
}
</script>