import Vue from 'vue'
import VueRouter from 'vue-router'
import Candidates from '../views/Candidates.vue'
import ElectionPortal from '../views/ElectionPortal.vue'
import Results from '../views/Results.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/candidates',
    name: 'Kandidat*innen',
    component: Candidates
  },
  {
    path: '/results',
    name: 'Ergebnisse',
    component: Results
  },
  {
    path: '/wahlen',
    name: 'Wahlen',
    component: ElectionPortal
  },
  {
    path: '/',
    name: 'Vertrauenspersonenwahl',
    component: ElectionPortal
  },
]

const router = new VueRouter({
  routes
})

export default router
