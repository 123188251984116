<template>
    <v-container v-resize="onResize" class="d-flex">
        <v-dialog :value="voting_dialog" fullscreen hide-overlay>
            <v-toolbar v-if="voting_user && !voted" absolute color="green lighten-2">
                <v-toolbar-title class="text-h4 text-capitalize">
                    Vertrauenspersonenwahl {{voting_user.username}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="close_dialog()" color="red" fab small>
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card class="ma-0 pa-0" style="background-color:white">

                <div style="border: 0px;" class="d-flex justify-center pt-6">
                    <v-card v-if="voting_user" :outlined="false" elevation="0">
                        <v-card-title primary-title class="text-h1 text-capitalize d-flex justify-center">
                            {{voted ? voting_user.username:''}}
                        </v-card-title>
                        <v-card-text class="d-flex pa-5 text-capitalize flex-wrap justify-center">
                            <v-card :disabled="!user.election.first_trust_person && trust_person_count ==1" @click="toggle_vote(user.id)" class="mr-3 mb-3" elevation="0"
                                :color="trust_persons.includes(user.id) && !voted && !(!user.election.first_trust_person && trust_person_count ==1) ? 'green lighten-1' : 'white'"
                                v-for="user in candidates" :key="user.id">
                                <v-card-title dense primary-title class="text-h4 py-1 d-flex justify-center">
                                    {{user.username}}
                                </v-card-title>
                            <!--    <v-icon x-large :color="user.election.first_trust_person ? 'green darken-2' : 'orange darken-2'" class="" style="position:absolute; z-index:30; top:70px; right:15px">{{user.election.first_trust_person ? 'person' : 'group'}}</v-icon> -->
                                <v-img class="ma-1" v-if="user.picture" :max-height="picture_size"
                                    :max-width="picture_size" :src="'https://wahlen.netzwerk-schule.net/assets/' + user.username + '.jpg'">
                                </v-img>
                                <v-img v-else @click="toggle_vote(user.id)" :max-height="picture_size"
                                    :max-width="picture_size" :src="user.avatar"></v-img>
                            </v-card>
                        </v-card-text>
                        <v-card-actions>
                        </v-card-actions>

                    </v-card>
                    <div v-if="!voted" class="d-flex" style="z-index:30;position:absolute;left: 30px; width:100%" :style="'bottom: ' + trust_person_count_question_position + 'px'">
                        <v-card elevation="0" width="70%" height="70px"
                            class="text-h4 d-flex align-center justify-center">
                            Wieviele Vertrauenspersonen möchtest du haben?
                        </v-card>
                        <v-btn @click="trust_person_count = 1" :color="trust_person_count == 1 ? 'green' : 'green lighten-4'" height="70px" width="10%" class="text-h3">1</v-btn>
                        <v-btn @click="trust_person_count = 2" :color="trust_person_count == 2 ? 'green' : 'green lighten-4'"  height="70px" width="10%" class="text-h3">2</v-btn>
                    </div>

                    <v-btn v-if="voted" class="text-h3" style="z-index:30;position:absolute; bottom:70px;left: 0px"
                        width="100%" height="80" color="yellow" @click="edit()">Bearbeiten</v-btn>
                    <v-btn class="text-h3" style="z-index:30;position:absolute; bottom:0px;left: 0px" width="100%"
                        height="70" :disabled="vote_valid && !voted" :color="voted ? 'success' : 'yellow'" @click="submit_vote">{{voted ? 'Schliessen' :
                        'Abgeben'}}</v-btn>

                </div>

            </v-card>

        </v-dialog>
        <v-card color="green" min-width="300px">
            <v-list :style="'height: ' + (windowSize.y -120) + 'px; overflow:auto'">
                <v-list-item link @click="vote(user)" class="text-capitalize text-h4" v-for="user in user_list.filter(u => u.election && u.election.can_vote)"
                    :key="user.id">
                    {{user.username}}
                    <v-spacer></v-spacer>
                    <v-icon large color="green" v-if="user.election && user.election.trust_persons.length > 0">mdi:
                        mdi-vote-outline</v-icon>
                </v-list-item>
            </v-list>

        </v-card>
        <v-card class="ml-5" elevation="0">
            <v-switch inset v-model="hide_finished" >
                <template v-slot:label>
                    <span class="text-h5">Leute die gewählt haben ausblenden </span>
                </template>
            </v-switch>
        </v-card>

    </v-container>
</template>

<script>
import urls from "../project_base/services/urls";
import http_auth from "../project_base/services/http-auth"
import permission from "../project_base/services/permission"
export default {
    computed: {
        trust_person_count_question_position () {
            let b = this.windowSize.y - 1780
            return b < 70 ? 70 : b
        },
        valid_candidates () {
            let valid_candidates = []
            for (let i=0;i < this.trust_persons.length; i++) {
                let candidate = this.candidates.filter(candidate => candidate.id == this.trust_persons[i])[0]
                if (candidate.election.first_trust_person) {
                    valid_candidates.push(candidate.id)
                } else if (this.trust_person_count == 2) {
                    valid_candidates.push(candidate.id)
                }
            }
            return valid_candidates
        },
        vote_valid () {
            if (this.trust_person_count == 1) {
                return this.candidates.some(c => this.trust_persons.includes(c.id) && c.election.first_trust_person) ? false : true
            } else if (this.trust_person_count == 2) {
                return this.candidates.some(c => this.trust_persons.includes(c.id) && c.election.first_trust_person && this.trust_persons.length > 0) ? false : true
            } else return true
        },
        user_list() {
            let users = this.$store.state.users.all.filter(user => !user.is_employee).sort(function (a, b) {
                return a.username.localeCompare(b.username)
            })
            users = this.hide_finished ? users.filter(user => !(user.election && user.election.trust_persons.length > 0)) : users
            return permission.check(this.$store.state.base.current_user, "see_election_list") ? users : users.filter(u => u.id == this.$store.state.base.current_user.id )
        },
        candidates() {
            let candidates = this.voted ? 
                        this.$store.state.users.all.filter(user => user.election && 
                                                                   user.election.electable && 
                                                                   this.trust_persons.includes(user.id) && 
                                                                   !(!user.election.first_trust_person && this.trust_person_count ==1)) 
                        :
                        this.$store.state.users.all.filter(user => user.election && user.election.electable)
            return candidates.sort(() => Math.random() - 0.5)
        },
        picture_size() {
            if (!this.voted) { return '250' }
            else if (this.trust_persons.length == 1) { return '600' }
            else if (this.trust_persons.length < 5) { return '500' }
            else if (this.trust_persons.length < 7) { return '400' }
            else if (this.trust_persons.length < 9) { return '350' }
            else if (this.trust_persons.length < 13) { return '300' }
            else return '250'
        }
    },
    mounted() {
        this.onResize()
    },
    methods: {
        onResize() {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        },
        edit() {
            this.voted = false
            clearTimeout(this.close_dialog_timer)
        },
        toggle_vote(username) {
            if (!this.voted) {
                if (this.trust_persons.includes(username)) {
                    for (var i = 0; i < this.trust_persons.length; i++) {
                        if (this.trust_persons[i] === username) {
                            this.trust_persons.splice(i, 1);
                        }
                    }
                } else {
                    this.trust_persons.push(username)
                }
            }

        },
        vote(user) {
            this.voting_user = user
            this.voting_dialog = true
            this.get_user_votes(user)
        },
        async get_user_votes(user) {
            const response = await http_auth.auth(this.$store.state.base.current_user.authentication_token)
                .post('/election/get_vote', {
                    user: user,
                })
            this.trust_persons = response.data.vote ? response.data.vote : []
            this.trust_person_count = response.data.trust_persons_count
            if (this.trust_persons && this.trust_persons.length > 0) { this.voted = true }
        },
        close_dialog() {
            this.voting_dialog = false
            this.voting_user = null
            this.voted = false
            this.trust_person_count = null

        },
        async submit_vote() {
            if (this.voted) {
                this.close_dialog()
                clearTimeout(this.close_dialog_timer)
            }
            else {
                let response = await http_auth.auth(this.$store.state.base.current_user.authentication_token)
                    .post('/election/vote', {
                        user: this.voting_user,
                        trust_persons: this.valid_candidates,
                        trust_person_count: this.trust_person_count
                    })
                if (response.data.success) {
                    let self = this
                    this.voted = true
                    this.close_dialog_timer = setTimeout(function () {
                        self.close_dialog()
                    }, 5000)
                }

            }


        }
    },
    data() {
        return {
            windowSize: {
                x: 0,
                y: 0
            },
            voting_user: null,
            voting_dialog: false,
            base_url: urls.base_url(),
            trust_persons: [],
            voted: false,
            close_dialog_timer: null,
            hide_finished: false,
            trust_person_count: null
        }
    },
}
</script>