import axios from 'axios'
import urls from '../services/urls'
// const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
// axios.defaults.headers.common['X-CSRF-Token'] = token
// axios.defaults.headers.common.Accept = 'application/json'

class HttpCommon {
  auth (token) {
    return axios.create({
      baseURL: urls.api_url(),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export default new HttpCommon()
