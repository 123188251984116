class permission {
  
    check(user, permission) {
      for(let i=0; i < user.roles.length;i++) {
        if (user.roles[i].permissions.includes(permission)) {
          return true
        }
      }
      return false
    }
  }
  export default new permission() 