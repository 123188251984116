import http_auth from '../../project_base/services/http-auth'

const auth = {
  namespaced: true,
  state: {
    all: null
  },

  mutations: {
    toggleDarkmode(state, value) {
      state.current_user.dark_mode = value
    },
    storeAll(state, data) {
      state.all = data
    },
    update_user(state, data) {
      var index = state.all.findIndex(user => user.id == data.id)
      index != -1 ? state.all.splice(index, 1) : null
      state.all.push(data)
    }
  },

  actions: {
    async getUsers(context) {
      const response = await http_auth.auth(context.rootState.base.current_user.authentication_token).get('user_manager/users')
      await context.commit('storeAll', response.data)
      console.log(response)
    },
  }
}


export default auth