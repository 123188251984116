const global = {

  data () {
    return {

    }
  },
  methods: {
    link_to(url) {
      if (this.$route.path != url) {
        this.$router.push(url);
      }
    },    
  },
  watch: {

  },
  mounted () {
    
  }
}

export default global