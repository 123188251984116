class environment {
  base_path() {
    if (window.location.hostname.split(".")[1] == "netzwerk-schule") {
      return 'benutzer.nwschule.de/backend/'
    }
    else return window.location.hostname + ":3300/"
  }
  encryption() {
    if (window.location.hostname.split(".")[1] == "netzwerk-schule") {
      return 's://'
    } else return '://'
  }
  base_url() {
    return 'http' + this.encryption() + this.base_path()
  }
  api_url() {
    return this.base_url() + "api/v1/"
  }
  cable_url() {
    return 'ws' + this.encryption() + this.base_path() + 'cable'
  }
  login_url() {
    return this.base_url()
  }
}
export default new environment()
